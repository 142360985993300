@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}

.navbar {
  height: 10vh;
  z-index: 1000;
}

.home {
  min-height: 90vh;
}
